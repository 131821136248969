@import 'styles/variables';
@import 'styles/mixins';

.container {
  padding-top: 120px;

  max-width: 940px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @include screen(maxLg) {
    padding-top: 90px;
    max-width: 90%;
  }
}
