@import 'styles/variables';
@import 'styles/mixins';

.item {
  padding: 17.5px;

  &__avatar {
    margin-bottom: 16px;
  }

  &__name {
    color: var(--Primary-White, #fff);
    font-family: MonumentExtended;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 0;
    line-height: 32px;

    @include screen(maxLarge) {
      font-size: 16px;
      line-height: 25.336px;
    }

    @media screen and (max-width: 576px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__position {
    color: #939393;
    font-family: Gilroy, sans-serif;
    font-size: 21.065px;
    font-style: normal;
    font-weight: 500;
    line-height: 35.108px;
    margin-bottom: 10px;

    @include screen(maxLarge) {
      font-size: 15.202px;
      line-height: 25.336px;
    }

    @media screen and (max-width: 576px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__icon {
    margin-right: 21px;
  }
}
