@import 'styles/variables';
@import 'styles/mixins';

.container {
  max-width: 1290px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 144px;
  padding-bottom: 100px;

  @media screen and (max-width: 576px) {
    padding-top: 130px;
  }
}

.title {
  width: fit-content;
  margin: auto;
  margin-bottom: 8px;
  font-family: MonumentExtended;
  font-weight: 600;
  font-size: 50px;
  font-style: normal;
  line-height: 72px;
  letter-spacing: -1px;
  background-size: 100%;
  background-repeat: repeat;
  background: linear-gradient(90deg, #ffbaf3 0.29%, #fff1d4 49.4%, #8bf3fc 99.54%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include screen(maxLarge) {
    font-size: 37.767px;
    line-height: 54.385px;
    letter-spacing: -0.755px;
  }

  @media screen and (max-width: 576px) {
    font-size: 25px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.5px;
    margin-bottom: 13px;
  }
}

.subtitle {
  text-align: center;
  color: #fff;
  font-family: MonumentExtended;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
  line-height: 72px;
  margin-bottom: 64px;

  @include screen(maxLarge) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 56px;
  }

  @media screen and (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  }
}

.card {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(276px, 420px));
}
