@import 'styles/variables';
@import 'styles/_mixins.scss';

.gradient_wrapper {
  background: var(--gradient-p-0);
  background-size: 200% 200%;
  padding: 1px 1px;
  border-radius: 8px;
  display: flex;
  animation: gradientAnimation 2s ease infinite;
}

.wrapper {
  display: flex;
  color: #fff;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--bg);
  width: 154px;
  height: 48px;

  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;

  @include screen(quadHd) {
    width: 205px;
    height: 64px;
    font-size: 22px;
    line-height: 16px;
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    font-size: 14px;
    font-family: IBMPlexMono, monospace;
  }

  &:hover {
    cursor: pointer;
    color: #000;
    @include primaryButton;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
