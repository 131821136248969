@import 'styles/variables';
@import 'styles/mixins';

.container {
    color: var(--Neutrals-Gray-200, #B2B2B2);
    font-family: IBMPlexMono-Regular, monospace;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;
    margin-bottom: 50px;

    @include screen(maxMd) {
        margin-bottom: 25px;
    }
}