//IBMPlexSans
@font-face {
  font-family: 'IBMPlexSans-Bold';
  font-style: normal;
  src: url('./IBMPlexSans-Bold.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-BoldItalic';
  font-style: normal;
  src: url('./IBMPlexSans-BoldItalic.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-ExtraLight';
  font-style: normal;
  src: url('./IBMPlexSans-ExtraLight.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-ExtraLightItalic';
  font-style: normal;
  src: url('./IBMPlexSans-ExtraLightItalic.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-Italic';
  font-style: normal;
  src: url('./IBMPlexSans-Italic.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-Light';
  font-style: normal;
  src: url('./IBMPlexSans-Light.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-LightItalic';
  font-style: normal;
  src: url('./IBMPlexSans-LightItalic.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-Medium';
  font-style: normal;
  src: url('./IBMPlexSans-Medium.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-MediumItalic';
  font-style: normal;
  src: url('./IBMPlexSans-MediumItalic.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-Regular';
  font-style: normal;
  src: url('./IBMPlexSans-Regular.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-SemiBold';
  font-style: normal;
  src: url('./IBMPlexSans-SemiBold.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-SemiBoldItalic';
  font-style: normal;
  src: url('./IBMPlexSans-SemiBoldItalic.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-Thin';
  font-style: normal;
  src: url('./IBMPlexSans-Thin.ttf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans-ThinItalic';
  font-style: normal;
  src: url('./IBMPlexSans-ThinItalic.ttf') format('opentype');
  font-display: swap;
}
