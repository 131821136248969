@import 'styles/_mixins';

.wrapper {
  margin: 0;
  font-family: 'Gilroy-Regular';
  color: #b2b2b2;
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 62px;
}

.title {
  margin: 0;
  font-family: 'Gilroy-Regular';
  color: var(--Neutrals-Gray-200, #b2b2b2);
  font-style: normal;
  white-space: break-spaces;
  margin-bottom: 40px;
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;

  @media screen and (max-width: 390px) {
    font-size: 36px;
  }

  @media screen and (max-width: 360px) {
    font-size: 32px;
  }
}

.text {
  margin-bottom: 25px;
  color: gray;
}

.text_content {
  position: relative;
}

.text_clone {
  overflow: hidden;
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  h2 {
    color: #fff;
  }

  p,
  a {
    color: #fff;
  }
}

.heading-span {
  font-size: 2em;
  font-weight: bold;
}

.link_animation {
  text-decoration: underline;
  color: gray;
}

.signature {
  margin-top: 40px;
  margin-bottom: 21px;
}

.section_footer {
  display: flex;
  align-items: center;
}

.peerLogo {
  width: 49px;
  margin-right: 15px;
}

.founder_info {
  color: var(--color-content-neutrals-secondaryLight, #808080);
  font-family: 'Gilroy-Regular';
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 2px;
}
