.terms {
  display: flex;
  gap: 16px;
  color: #fff;
  font-family: Gilroy-Regular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  a {
    color: #fff;
    &:hover {
      color: var(--main-link-hover);
    }
  }
}
