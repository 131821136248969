@import 'styles/variables';
@import 'styles/mixins';

.container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @include screen(maxSm) {
        flex-direction: column;
        align-items: start;
        margin-bottom: 20px;
    }

    .title {
        color: var(--Primary-White, #FFF);
        font-family: Gilroy, sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 45px;
        margin-bottom: 10px;
        max-width: 500px;
    }

    .apply_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        padding: 20px 87px;
        color: #939393;
        font-weight: 500;
        font-size: 20px;
        font-family: 'Gilroy', sans-serif;
        line-height: 0;
        letter-spacing: -0.656px;
        text-align: center;
        border: 2px solid #333;
        border-radius: 40px;
        cursor: pointer;
        transition: 0.3s;
        flex-shrink: 0;
    
        @include screen(lg) {
            min-width: 240px;
        }
    
        @include screen(maxSm) {
            padding-right: 65px;
            padding-left: 65px;
            font-size: 20px;
            min-width: 90%;
            align-self: center;
        }
    
        &:hover {
            color: #fff;
            text-decoration: none;
            border-color: #fff;
        }
    }
}