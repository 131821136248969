@import 'styles/_mixins';

.container {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  background-color: #000000;
  transition: transform 0.3s ease-in;

  @media screen and (max-width: 900px) {
    z-index: 10001;
    background-color: rgba(0, 0, 0, 0);
  }

  &.hidden {
    transform: translateY(-270%);
    transition: transform 0.3s ease-in;
  }
  &.bg {
    backdrop-filter: saturate(180%) blur(20px);
  }
  .main_container {
    display: flex;
    flex-grow: 2;
    align-items: center;
    justify-content: flex-start;
    gap: 41px;
    width: 100%;
    margin: 0 auto;
    padding: 17px 17px;

    @include screen(maxMd) {
      gap: 30px;
    }

    @include screen(quadHd) {
      padding: 22.6px;
      gap: 54.67px;
    }

    .gradientLink {
      display: flex;
      align-items: center;
      color: #fff;
      font-family: 'Gilroy', sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;
      gap: 6px;
      cursor: pointer;

      @include screen(quadHd) {
        font-size: 14px;
        letter-spacing: 1.4px;
      }

      span {
        background: var(--gradient-p-0);
        background-size: 200% 200%;
        animation: gradientAnimation 2s ease infinite;
        text-decoration: none;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        background: #fff;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      img {
        transition: transform 0.3s ease;

        @include screen(quadHd) {
          height: 8px;
        }
      }

      &:hover img {
        transform: translateX(12px);
      }
    }

    .alignStart {
      align-self: flex-start;
    }

    &:after {
      position: absolute;
      top: 95%;
      right: 0;
      left: 0;
      height: 10px;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      content: '';

      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    .get_peer_wrapper {
      position: absolute;
      top: 27px;
      right: 23px;
      z-index: 10000;

      @include screen(quadHd) {
        top: 36px;
        right: 30.67px;
      }
    }
  }
}

.peerLink {
  display: flex;
  align-items: center;
}

.peerLogoSmall {
  width: 20px;
  height: 20px;
  opacity: 0.5;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  @include screen(quadHd) {
    width: 26.6px;
  }
}

.peerLogo {
  width: 49px;
  opacity: 0.5;
  transition: opacity 0.3s;

  @include screen(quadHd) {
    width: 60px;
  }

  &:hover {
    opacity: 1;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
