.name {
  color: var(--Neutrals-Gray-200, #b2b2b2);
  margin-right: 14px;
  font-weight: 600;
}

.link {
  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: var(--Neutrals-Gray-200, #b2b2b2);
  }
}

.link svg {
  transition: transform 0.3s ease;
}

.link:hover svg {
  transform: translateX(12px);
}
