@import 'styles/_mixins';

.titleContainer {
  position: relative;
  height: 60px;
  width: 100%;
  z-index: 1000;

  @include screen(quadHd) {
    height: 55px;
    z-index: 1000;
  }

  @media screen and (max-width: 680px) {
    height: 73px;
    z-index: 1000;
  }
}

.title {
  color: #fff;
  text-align: center;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.96px;
  text-transform: lowercase;
  margin: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 45px;
  font-size: 45px;

  @include screen(quadHd) {
    font-size: 60px;
    line-height: 24px;
    letter-spacing: 1.28px;
    height: 60px;
  }

  @media screen and (max-width: 680px) {
    line-height: 35px;
    height: 25px;
    padding: 0 20px;
    font-size: 25px;
  }
}

.hidden {
  transform: translateY(100%);
  opacity: 0;
}

.animateOut {
  transform: translateY(-100%);
  opacity: 0;
  transition:
    transform 1s ease-in-out,
    opacity 1s ease-in-out;
}

.animateIn {
  transform: translateY(0);
  opacity: 1;
  transition:
    transform 1s ease-in-out,
    opacity 1s ease-in-out;
}
