@font-face {
  font-family: 'Turbine-Thin';
  src: url('./Turbine-Thin.eot');
  src: url('./Turbine-Thin.eot?#iefix') format('embedded-opentype'),
    url('./Turbine-Thin.woff2') format('woff2'),
    url('./Turbine-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Turbine-Light';
  src: url('./Turbine-Light.eot');
  src: url('./Turbine-Light.eot?#iefix') format('embedded-opentype'),
    url('./Turbine-Light.woff2') format('woff2'),
    url('./Turbine-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Turbine-Regular';
  src: url('./Turbine-Regular.eot');
  src: url('./Turbine-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Turbine-Regular.woff2') format('woff2'),
    url('./Turbine-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Turbine-Medium';
  src: url('./Turbine-Medium.eot');
  src: url('./Turbine-Medium.eot?#iefix') format('embedded-opentype'),
    url('./Turbine-Medium.woff2') format('woff2'),
    url('./Turbine-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Turbine-Semibold';
  src: url('./Turbine-Bold.eot');
  src: url('./Turbine-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Turbine-Bold.woff2') format('woff2'),
    url('./Turbine-Bold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Turbine-Bold';
  src: url('./Turbine-Bold.eot');
  src: url('./Turbine-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Turbine-Bold.woff2') format('woff2'),
    url('./Turbine-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Turbine-Black';
  src: url('./Turbine-ExtraBold.eot');
  src: url('./Turbine-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./Turbine-ExtraBold.woff2') format('woff2'),
    url('./Turbine-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
