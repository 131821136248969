// MEDIA VARS
$phone: 480px;
$sm: 576px;
$tablet: 768px;
$tablet-lg: 962px;
$laptop: 1200px;
$desktop: 1500px;
$large-screen: 1500px;
$fullHd-screen: 1920px;
$quadHd-screen: 2560px;

:root {
  --font: #000000;
  --bg: #fff;
  --avaliable: #62c31c;
  --unavaliable: #f62e2e;
  --error: #d55f97;
  --link: #00d3f9;
  --link-hover: #ffffff;
  --btn-link-hover: #ffffff;
  --avaliableDomenbg: #f4f4f5;
  --main-font: #000;
  --main-link-hover: #747474;
  --gradient-p-0: linear-gradient(262deg, #ffbaf3 11.2%, #fff1d4 50.33%, #8bf3fc 90.29%);
  --gradient-p-1: linear-gradient(262deg, #6E3ADB, #494DFD, #2B9CED);
  --gradient-p-2: linear-gradient(262deg, #6E3ADB 11.2%, #494DFD 50.33%, #2B9CED 90.29%);
  --Neutrals-Gray-200: #b2b2b2;
}

.dark {
  --font: #fff;
  --bg: #000;
  --main-font: #fff;
  --main-font-hover: rgba(255, 255, 255, 0.3);
  --avaliableDomenbg: #000;
  --main-border: #2e2e2e;
  --main-link-hover: #747474;
}
