.section {
  margin: 0;
  margin-top: 50px;
  font-family: 'Gilroy-Regular', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 62px;
  color: var(--Neutrals-Gray-200, #b2b2b2);
}

.title {
  margin: 0;
  font-family: Gilroy, sans-serif;
  color: var(--Neutrals-Gray-200, #b2b2b2);
  font-style: normal;
  white-space: break-spaces;
  margin-bottom: 50px;
  font-size: 40px;
  line-height: 45px;
  font-weight: 600;
}

.subtitle {
  font-family: 'Gilroy-Regular', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 25px;
}

.investors_names {
  font-family: Caveat, sans-serif;
  color: var(--Neutrals-Gray-200, #b2b2b2);
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 50px;
}

.text {
  font-family: 'Gilroy-Regular', sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 62px;
  color: var(--Neutrals-Gray-200, #b2b2b2);
}
