@import 'styles/_mixins';

.footer {
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px;

  @include screen(quadHd) {
    padding: 0 60px 60px;
  }

  @media screen and (max-width: 680px) {
    max-width: 300px;
  }

  hr {
    height: 1px;
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgb(255, 255, 255, 0.1);
    margin: 0 0 22px 0;

    @include screen(quadHd) {
      height: 2px;
      margin-bottom: 29.3px;
    }

    @media screen and (max-width: 680px) {
      display: none;
    }
  }
}

.links_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 57px;

  @include screen(quadHd) {
    margin-bottom: 75px;
  }

  @media screen and (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 34px;
  }
}

.largeLink {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: 'Gilroy', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  @include screen(quadHd) {
    font-size: 40px;
    line-height: 33.333px;
    gap: 15px;
  }

  img {
    height: 25px;
    transition: transform 0.3s ease;

    @include screen(quadHd) {
      height: 32px;
    }
  }

  &:hover img {
    transform: translateX(12px);
  }
}

.container {
  z-index: 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background: var(--bg);

  // NOTE: to fix terms & privacy at center
  .item {
    flex: 1;
  }

  .item:nth-child(2) {
    text-align: center;
    flex: 0;
  }

  .terms_container {
    display: flex;
    color: #fff;
    font-family: MonumentExtended, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;

    @include screen(quadHd) {
      font-size: 14px;
    }

    .terms_element {
      width: 60px;
      text-align: right;

      @include screen(quadHd) {
        width: 81px;
      }
    }

    a {
      color: #fff;

      &:hover {
        color: var(--main-link-hover);
      }
    }
  }

  .socials {
    transform: translateX(7px);
    display: flex;
    gap: 34px;
    justify-content: flex-end;

    @include screen(quadHd) {
      gap: 45px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;

      &:hover {
        color: var(--main-link-hover);
      }

      svg {
        transform: scale(0.6);

        @include screen(quadHd) {
          transform: none;
        }
      }
    }
  }
}

.copyright_container {
  display: flex;
  color: #fff;
  font-family: MonumentExtended, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  gap: 25px;

  .peerLogo {
    width: 49px;

    @include screen(quadHd) {
      width: 66px;
    }
  }

  @include screen(quadHd) {
    font-size: 16px;
    gap: 33.3px;
  }
}
