@mixin flex_container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

.container {
  position: relative;
  width: 100%;
  padding: 80px 35px;
  background-color: var(--main-bg);

  .flex_container {
    @include flex_container;
    min-height: calc(100vh - 160px);
  }
}
