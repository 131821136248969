@import 'styles/variables';
@import 'styles/mixins';

.container {
  max-width: 930px;
  margin: 0 auto;
  padding: 78px 15px;
}

.title {
  color: #fff;
  text-align: center;
  font-family: MonumentExtended, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -1.2px;
  margin: 0;

  @include screen(md) {
    font-size: 100px;
    letter-spacing: -2px;
  }
}

.content {
  display: grid;
  grid-gap: 25px;
  margin-top: 40px;

  @include screen(md) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top: 92px;
  }
}
