.container {
    color: var(--Neutrals-Gray-200, #B2B2B2);
    font-family: Gilroy-Regular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 25px;
    padding-left: 10px;

    list-style: inherit;

    ul, ol {
        padding-top: 10px;
    
        li {
            margin-bottom: 10px;
        }
    }
}