@import 'styles/variables';
@import 'styles/_mixins';

.wrapper {
  overflow: hidden;
  display: flex;
  padding-top: 153px;
  background: var(--bg);
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include screen(quadHd) {
    padding-top: 230.6px;
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    flex-grow: 1;
    justify-content: space-between;
    padding-top: 100px;
  }
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 550px;
  max-height: 550px;
  position: relative;

  //NOTE: to compensate big video size
  margin-top: -25px;
  margin-bottom: -25px;

  @include screen(quadHd) {
    max-width: 750px;
    max-height: 750px;
  }

  @media screen and (max-width: 680px) {
    width: 340px;
    height: 340px;
    margin-bottom: 0;
  }

  video {
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  }
}

.links_container {
  width: 100%;
  margin-bottom: 57px;
  flex-direction: column;

  @media screen and (max-width: 680px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 35px;
  }

  @include screen(quadHd) {
    margin-bottom: 75px;
  }
}

.largeLink {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: 'Gilroy', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  @include screen(quadHd) {
    font-size: 32px;
    line-height: 40px;
  }

  img {
    height: 18px;
    transition: transform 0.3s ease;

    @include screen(quadHd) {
      height: 30px;
    }
  }

  &:hover img {
    transform: translateX(12px);
  }
}

.gradientLink {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: 'Gilroy', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 1px;
  text-transform: uppercase;
  gap: 6px;
  cursor: pointer;

  @include screen(quadHd) {
    font-size: 16px;
    letter-spacing: 1.6px;
  }

  span {
    background: var(--gradient-p-0);
    background-size: 200% 200%;
    animation: gradientAnimation 2s ease infinite;
    text-decoration: none;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  img {
    transition: transform 0.3s ease;

    @include screen(quadHd) {
      height: 8px;
    }
  }

  &:hover img {
    transform: translateX(12px);
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.opacity {
  opacity: 1 !important;
}

.opacity_delay {
  opacity: 1 !important;
  transition: opacity 0.3s;
}
