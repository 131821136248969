.heading {
  margin: 0;
  font-family: 'Gilroy-Regular';
  color: var(--Neutrals-Gray-200, #b2b2b2);
  font-style: normal;
  white-space: break-spaces;

  &.size_1 {
    font-size: 40px;
    line-height: 45px;
    font-weight: 600;
  }

  &.size_2 {
    font-size: 25px;
    line-height: 25px;
    font-weight: 600;
  }
}

.paragraph {
  margin: 0;
  font-family: 'Gilroy-Regular';
  color: var(--Neutrals-Gray-200, #b2b2b2);
  font-style: normal;
  white-space: break-spaces;

  &.size_1 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }
}
