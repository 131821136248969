@import 'styles/variables';
@import 'styles/mixins';

.story_wrapper {
  margin: 0 auto;
  max-width: 444px;
  color: var(--Neutrals-Gray-200, #b2b2b2);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  min-height: 120vh;
}

hr {
  height: 1px;
  border: 0;
  border-bottom: 1px solid rgb(255, 255, 255, 0.1);
  margin: 0;
}

.peer_logo {
  position: relative;
  left: 2px;
}

.introduction {
  margin-top: 40px;
  margin-bottom: 69px;

  @include screen(sm) {
    margin-top: 76px;
  }
}

.text {
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 40px;
  }
}

.title {
  margin-bottom: 30px;
}

.title_second {
  margin-bottom: 48px;
}

.links_wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 77px;
  margin-top: 49px;
}

.email {
  display: block;
  font-family: 'Gilroy-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
}

.email_link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
