.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
.fade-enter-done {
  opacity: 1;
}

#theme {
  background-color: var(--bg);
}
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: space-between;
  min-height: 100vh;
  overflow: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
}

.fadeInOut-appear {
  z-index: 1;
  opacity: 0;
}

.fadeInOut-appear.fadeInOut-appear-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.fadeInOut-enter {
  z-index: 1;
  opacity: 0;
}

.fadeInOut-enter.fadeInOut-enter-active {
  opacity: 1;
  transition: opacity 1000ms linear 1000ms;
}

.fadeInOut-exit {
  opacity: 1;
}

.fadeInOut-exit.fadeInOut-exit-active {
  opacity: 0;
  transition: opacity 1000ms linear;
}

.fadeInOut-exit-done {
  opacity: 0;
}

.transition_group {
  flex-grow: 1;
}
