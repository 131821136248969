@import 'styles/mixins';
@import 'styles/variables';

$circle-size: 50%;
$blending: hard-light;

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

.gradient_bg {
  width: 700px;
  height: 300px;
  position: absolute;
  top: -220px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  background: linear-gradient(40deg, rgb(252, 252, 186), rgb(251, 207, 150));
  filter: blur(70px);
  border-radius: 50%;
  z-index: 10000;
  transition: none !important;

  @include screen(maxMd) {
    width: 100%;
    border-radius: 25%;
  }

  .g1 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(204, 24, 255, 0.8) 0,
        rgba(204, 24, 255, 0) 100%
      )
      no-repeat;
    mix-blend-mode: $blending;

    width: $circle-size;
    height: calc($circle-size * 2);
    top: calc($circle-size / 2);
    left: calc(100% - $circle-size + 10%);

    transform-origin: center center;
    animation: moveVertical 6s ease infinite;

    opacity: 1;
    transition: none !important;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(252, 231, 109, 0.8) 0,
        rgba(252, 231, 109, 0) 100%
      )
      no-repeat;
    mix-blend-mode: $blending;

    width: $circle-size;
    height: calc($circle-size * 2);
    top: calc(50% - $circle-size / 2);
    left: calc(50% - $circle-size / 2);

    transform-origin: calc(50% - 400px);
    animation: moveInCircle 6s reverse infinite;

    opacity: 1;
    transition: none !important;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(200, 50, 50, 0.8) 0,
        rgba(200, 50, 50, 0) 100%
      )
      no-repeat;
    mix-blend-mode: $blending;

    width: $circle-size;
    height: calc($circle-size * 2);
    top: calc($circle-size / 2);
    left: -10%;

    animation: moveVertical 8s ease infinite;

    opacity: 0.7;
    transition: none !important;
  }
}
