@font-face {
  font-weight: 100;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-Thin.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-weight: 200;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-ExtraLight.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-weight: 300;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-Light.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-weight: 400;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-Regular.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-Medium.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-weight: 600;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-SemiBold.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-Bold.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-weight: 800;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-ExtraBold.ttf) format('ttf');
  font-display: swap;
}

@font-face {
  font-weight: 900;
  font-family: 'Inter';
  font-style: normal;
  src: url(./Inter-Black.ttf) format('ttf');
  font-display: swap;
}
