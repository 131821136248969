@import 'styles/variables';
@import 'styles/mixins';

.item {
  display: flex;
  align-items: center;
  padding: 23px 25px 25px 23px;
  border-radius: 20px;
  justify-content: space-between;
  gap: 10px;
  text-decoration: none !important;

  @include screen(md) {
    padding: 23px 32px 32px 37px;
  }

  &:hover {
    background: #151515;
  }

  &__title {
    color: #fff;
    font-family: 'Gilroy', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.428;
  }

  &__text {
    color: #808080;
    font-family: IBMPlexMono-Medium, monospace;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 7px;
  }
  &__arrow {
    flex-shrink: 0;
  }
}
