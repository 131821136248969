@import 'styles/variables';
@import 'styles/mixins';

.container {
    margin-bottom: 55px;

    @include screen(maxMd) {
        margin-bottom: 40px;
    }

    .back_link {
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;

        &:hover {
            text-decoration: none;
        }
    }

    .title {
        color: #FFF;
        text-align: center;
        font-family: MonumentExtended, sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 133.333% */
        letter-spacing: -0.6px;
        text-decoration: none;
        margin-bottom: 0;
    }
}